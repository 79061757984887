.container {
    top: 0;
    background-color: #bcc5e9ff;
    min-height: 100vh;
}

.mainContainer {
    display: flex;
    justify-content: center;
    cursor: default;
}

.bottomContainer {
    display: flex;
    justify-content: center;
    cursor: default;
    border-top: 1px solid #ffffffff;
    margin-top: 25px;
}

.altContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navItem {
    display: inline-flex;
    margin: 2%;
    color: white;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
    text-decoration: none;
}

.splash {
    max-width: 90%;
}

.imgCont {
    max-width: 100%;
    height: auto;
    justify-content: center;
    display: flex;
}

.input {
    color: white;
    text-align: center;
    font-size: large;
}

.emailBox {
    border-radius: 4px;
    margin: 2%;
}

.submitButton {
    background-color: white;
    color:#bcc5e9ff;
    border: none;
    margin: 2%;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.badButton {
    background-color: rgb(255, 134, 134);
    color:#bcc5e9ff;
    border: none;
    margin: 2%;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.submitButton:hover {
    background-color: antiquewhite;
}

.submitButton:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.privacy {
    margin: 3%;
    background-color: #bcc5e9ff;
}

.footLogo {
    width: 35px;
    margin: 20px;
}